import React from "react"
import { Routes } from "../../data/routes"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Content = {
  pageName: "Privacy",
  description: "Yup Privacy Policy",
}

const Privacy = () => {
  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.description}
        route={Routes.privacy}
      />
      <div className="p-5 max-w-7xl mx-auto">
        <div className="pb-5 border-b border-gray-200">
          <h1 className="text-xl leading-6 font-medium text-gray-900">
            Privacy Policy
          </h1>
        </div>
        <p className="font-bold mt-4">
          We are committed to protecting your online privacy and safety. This
          Privacy Policy explains what information we collect when you use the
          Yup Service, how the information will be used, and how you can control
          the collection, correction and/or deletion of information. This
          Privacy Policy applies only to information you provide us through the
          Yup Services. Capitalized terms that are not defined in this Privacy
          Policy have the meaning given to them in our Terms of Use.
        </p>
        <hr className="my-4" />
        <h2 className="text-lg">What We Collect</h2>
        <h4 className="font-bold mt-3">Personal Information</h4>
        <p>
          When you register to use the Yup Service (including by downloading the
          Yup application, linking your account to the Yup Service, etc.), you
          provide us information about yourself, such as your name, phone number
          and e-mail address. If you send email to us, we may retain your email,
          your email address and our responses. We may also retain any messages
          you send through the Service. You may provide us information in User
          Content you post to the Service. You may also provide us with
          financial payment information, geolocation information, Unique Device
          ID (UDID), and profile pictures, all subject to the applicable privacy
          settings.
        </p>
        <h4 className="font-bold mt-3">Contact</h4>
        <p>
          When you give Yup permission to access your contacts, we securely
          upload and store a copy of your contacts on our servers in order to
          provide you with contact-related services. Yup uses this information
          only to find useful actions for you. We do not share this information
          with any other third parties. This information is always kept
          securely. This information is kept as long as your account is active.
        </p>
        <h4 className="font-bold mt-3">Log File Information</h4>
        <p>
          When you use the Service, our servers automatically record certain log
          file information. These server logs may include information such as
          your web request, Internet Protocol ("IP") address, browser type,
          referring / exit pages and URLs, number of clicks and how you interact
          with the Service, domain names, landing pages, pages viewed, and other
          such information. Clear Gifs Information. When you use the Service, we
          may employ clear gifs (also known as web beacons), which are used to
          track the usage patterns of our users anonymously. In addition, we may
          also use clear gifs in HTML-based emails sent to our users to track
          which emails are opened by recipients. The information is used to
          enable more accurate reporting, improve the effectiveness of our
          marketing, and make Yup better for our users. Third Party Services Yup
          uses third party analytics services like Uservoice, Flurry, Hockey
          App, Mixpanel and Google Analytics to help understand use of the
          Service. These third party analytics services collect the information
          sent by your usage of the Service. Yup will not and cannot store any
          password associated with your Gmail or Yahoo! account. For all other
          accounts, Yup stores your passwords in a secure database.
        </p>
        <hr className="my-4" />
        <h2 className="text-lg">How We Use Your Information</h2>
        <p>
          We use the personal information you submit to operate, maintain, and
          provide to you the features and functionality of the Service. By
          providing Yup your email address and/or phone number, you consent to
          our using the email address and/or phone number to send you
          Service-related notices and receipts, including any notices required
          by law, in lieu of communication by postal mail. You also agree that
          we may send you notifications of activity on the Service to the email
          address and/or phone number you give us, in accordance with any
          applicable privacy settings. We may use your email address and/or
          phone number to send you other messages, such as newsletters, changes
          to features of the Service, or special offers. If you do not want to
          receive such email messages, you may opt out by selecting
          "unsubscribe" at the bottom of our email correspondences or STOP in
          text correspondences. Opting out may prevent you from receiving email
          messages and/or texts regarding updates, improvements, or offers. You
          may not opt out of Service-related e-mails. If you choose to use our
          invitation service to invite a friend to the Service, we will ask you
          for that person's email address and/or phone number and automatically
          send an email invitation or text. Yup stores this information to send
          this email or text, to register your friend if your invitation is
          accepted, and to track the success of our invitation service. Your
          friend may contact us to request that we remove this information from
          our database. Yup may use certain information about you and/or your
          User Content internally for purposes such as analyzing how the Service
          is used, diagnosing service or technical problems, maintaining
          security, and personalizing content. We use clear gifs, and log file
          information to: (a) remember information so that you will not have to
          re-enter it during your visit or the next time you visit the Service;
          (b) provide custom, personalized content and information; (c) monitor
          the effectiveness of our Service; (d) monitor aggregate metrics such
          as total number of visitors, traffic, and demographic patterns; (e)
          diagnose or fix technology problems reported by our Users or engineers
          that are associated with certain IP addresses; (f) help you
          efficiently access your information after you sign in; and (h) track
          User Content and Users to the extent necessary to comply as a service
          provider with the Digital Millennium Copyright Act; and (i)
          automatically update the Yup application on your system and related
          devices.
        </p>
        <hr className="my-4" />
        <h2 className="text-lg">How We Share Your Information</h2>
        <h4 className="font-bold mt-3">Personally Identifiable Information</h4>
        <p>
          Yup will not rent or sell your personally identifiable information to
          others. Yup may share your personally identifiable information with
          third parties for the purpose of providing the Service to you. If we
          do this, such third parties' use of your information will be bound by
          this Privacy Policy. We may store personal information in locations
          outside the direct control of Yup (for instance, on servers or
          databases co-located with hosting providers). We may also transfer or
          assign your information in the course of corporate divestitures,
          mergers, or dissolution. Any personal information or content that you
          voluntarily disclose for posting to the Service, such as User Content,
          becomes available to the public, as controlled by any applicable
          privacy settings. If you remove information that you posted to the
          Service, copies may remain viewable in cached and archived pages of
          the Service, or if other Users have copied or saved that information.
          Yup may disclose your personal information if required to do so by law
          or subpoena or if we believe that such action is necessary to (a)
          conform to the law, comply with legal process served on us or our
          affiliates, or investigate, prevent, or take action regarding
          suspected or actual illegal activities; (b) to enforce our Terms &amp;
          Conditions, take precautions against liability, to investigate and
          defend ourselves against any third-party claims or allegations, to
          assist government enforcement agencies, or to protect the security or
          integrity of our site; and (c) to exercise or protect the rights,
          property, or personal safety of Yup, our Users or others. We may share
          non-personally identifiable information (such as anonymous usage data,
          referring/exit pages and URLs, platform types, number of clicks, etc.)
          with interested third parties to help them understand the usage
          patterns for certain Yup services.
        </p>
        <hr className="my-4" />
        <h2 className="text-lg">How We Protect Your Information</h2>
        <p>
          Yup cares about the integrity and security of your personal
          information. We keep your personally identifiable information in
          encrypted form on a secured server behind a firewall. We also use
          automated and social measures to enhance security, such as analyzing
          account behavior for fraudulent or otherwise anomalous behavior, may
          limit use of site features in response to possible signs of abuse, may
          remove inappropriate content or links to illegal content, and may
          suspend or disable accounts for violations of our Terms of Use or
          Privacy Policy. Although we allow you to set privacy options that
          limit access to your information, please be aware that no security
          measures are perfect or impenetrable. We cannot control the actions of
          other users with whom you share your information. We cannot guarantee
          that only authorized persons will view your information. We cannot
          ensure that information you share on Service will not become publicly
          available. We are not responsible for third party circumvention of any
          privacy settings or security measures on Service. You can reduce these
          risks by using common sense security practices such as choosing a
          strong password, using different passwords for different services.
          Your privacy settings may also be affected by changes to the
          functionality of Yup's distributors, such as social networks. Yup is
          not responsible for the functionality or security measures of any
          third party.
        </p>
        <hr className="my-4" />
        <h2 className="text-lg">Compromise of Personal Information</h2>
        <p>
          In the event that personal information is compromised as a result of a
          breach of security, Yup will promptly notify those persons whose
          personal information has been compromised, in accordance with the
          notification procedures set forth in this Privacy Policy, or as
          otherwise required by applicable law. You may, of course, decline to
          submit personally identifiable information through the Service, in
          which case Yup may not be able to provide certain services to you. You
          may update or correct your account information at any time by logging
          in to your account through the Settings panel. You can review and
          correct the information about you that Yup keeps on file by contacting
          us directly. Yup does not knowingly collect or solicit personal
          information from anyone under the age of 13 or knowingly allow such
          persons to register as Members. If you are under 13, please do not
          send any information about yourself to us, including your name,
          address, telephone number, or email address. No one under age 13 is
          allowed to use the Yup Service or provide any personal information to
          or on Yup. If we become aware that we have collected personal
          information from a child under age 13 without verification of parental
          consent then we will delete that information as quickly as possible.
          If you believe that we might have any information from or about a
          child under 13, please contact us. We are not responsible for the
          practices employed by websites or applications linked to or from the
          Service, nor the information or content contained therein. Please
          remember that when you use a link to go from the Service to another
          website, our Privacy Policy is no longer in effect. Your browsing and
          interaction on any other website or application, including those that
          have a link on our website, is subject to that website's own rules and
          policies. Please read over those rules and policies before proceeding.
          It is our policy to provide notifications, whether such notifications
          are required by law or are for marketing or other business related
          purposes, to you via email notice, written or hard copy notice, or
          through conspicuous posting of such notice on the Service, as
          determined by Yup in its sole discretion. We reserve the right to
          determine the form and means of providing notifications to you,
          provided that you may opt out of certain means and types of
          notification as described in this Privacy Policy. Yup may update its
          Privacy Policy from time to time, so you should review this Policy
          periodically. When we change the policy in a material way, we will
          update the 'last modified' date at the top of this Privacy Policy.
          Changes to this Privacy Policy are effective when they are posted on
          this page. If you have any questions about this Privacy Policy, the
          practices of this site, or your dealings with this website, please
          contact us, or send mail to:
        </p>
        <p className="mt-2">Yup Technologies</p>
        <p>1005 Lombard St</p>
        <p>San Francisco, CA 94109</p>
        <hr className="my-4" />
        <h2 className="text-lg">Yup Google Adwords and Remarketing Policy</h2>
        <p>
          Yup uses all features of Google Analytics for display Advertisers.
          That includes obtaining specific visitor cookie data, such as the
          source, medium and keyword used to visit our website. Google analytics
          does not store any visitor specific data and we will not use visitor
          specific data in any way related to Google Analytics, Google Adwords,
          and Remarketing.
        </p>
        <p>
          Whilst Yup does not display advertisements in any way, other
          third-party vendors such, including Google, use first-party cookies
          (such as the Google Analytics Cookie) and other third-party cookies
          (such as the DoubleClick cookie) together to inform, optimize and
          serve ads based on visitor's past visits to our website.
        </p>
        <p>
          Yup does collect data on users Age, Gender and Interest to better
          identify our target demographics. None of the data collected can be
          used to identify individual users in any capacity.
        </p>
        <p>
          Users can choose to opt-out of Google Analytics for Display
          Advertisers and opt out of customized Google Display Network ads by
          visiting the Ads Preferences Manager.
        </p>
      </div>
    </Layout>
  )
}

export default Privacy
